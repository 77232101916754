import React from "react";
import Box from "@mui/material/Box";
import Slider from "react-slick";
import BannerScreenOne from "../../Assets/frame-one.webp";
import BannerScreenTwo from "../../Assets/frame-two.webp";
import BannerScreenThree from "../../Assets/frame-three.webp";
import BannerImagefour from "../../Assets/BannerImagfour.png"
import BannerImageFive from "../../Assets/BannerImageFive.png"
import BannerImageSix from "../../Assets/BannerImageSix.png"

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        background: "#34a9e2",
        padding: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        position: "absolute",
        right: "20px",
        borderRadius: "10px",
        // zIndex: 1000000,
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        background: "#34a9e2",
        padding: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        position: "absolute",
        left: "20px",
        zIndex: 1,
        borderRadius: "10px",
      }}
      onClick={onClick}
    />
  );
}
const HomeBanner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
  };
  const reiewData = [
    {
      image: BannerScreenOne,
    },
    {
      image: BannerScreenThree,
    },
    {
      image: BannerScreenTwo,
    },
    {
      image: BannerImagefour,
    },
    {
      image: BannerImageFive,
    },
    {
      image: BannerImageSix,
    },
  ];
  return (
    <Box>
      <Slider {...settings}>
        {reiewData?.map((item, index) => (
          <Box sx={{ height: "auto", pb: 0, mb: -1 }}>
            <img
              src={item?.image}
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "75vh",
                backgroundSize: "fit-content",
                objectFit: "fit-content",
                // margin: "0 auto",        // Center horizontally
              }}
              alt="clients"
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default HomeBanner;
