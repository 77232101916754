import React from "react";
import PoolSafety from "../Components/PoolSafety";
import ServiceBanner from "../Components/ServiceBanner";
import Breadcrumb from "../Components/Common/BreadCrumb";

const PoolSafetyPage = () => {
  const currentPath = "Pool Compliance Certification ";
  const message = "Pool Compliance Certification ";
  return (
    <div>
    <Breadcrumb path={currentPath} message={message} />
      <PoolSafety />
      <ServiceBanner />
    </div>
  );
};

export default PoolSafetyPage;
