import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Components/Common/Footer";
import Navbar from "./Components/Common/Navbar";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import Blog from "./Pages/Blog";
import Faqs from "./Pages/Faqs";
import CaseStudies from "./Pages/CaseStudies";
import BookOnline from "./Pages/BookOnline";
import InspectionServices from "./Pages/InspectionServices";
import AsbestosAwareness from "./Pages/AsbestosAwareness";
import TaxDepreciations from "./Pages/TaxDepreciation";
import PoolSafetyPage from "./Pages/PoolSafety";
import TimberPest from "./Pages/MouldTest";
import NewConstructionStage from "./Pages/NewConstructionStage";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faqs" element={<Faqs />} />
        {/* <Route path="/services/tax-depreciation" element={<Blog />} /> */}
        <Route path="/case-studies" element={<CaseStudies />} />
        <Route path="/book-online" element={<BookOnline />} />
        <Route path="/services/inspection" element={<InspectionServices />} />
        <Route
          path="/services/asbestos-awareness"
          element={<AsbestosAwareness />}
        />
        <Route
          path="/services/tax-depreciation"
          element={<TaxDepreciations />}
        />
        <Route path="/services/pool-safety" element={<PoolSafetyPage />} />
        <Route path="/services/timber-pest-inspections" element={<TimberPest/>} />
        <Route path="/services/new-construction-stage" element={<NewConstructionStage/>}/>
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
