import React from "react";
import Breadcrumb from "../Components/Common/BreadCrumb";
import Damage from "../Components/Damage";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import Quote from "../Components/Quote";

const About = () => {
  const currentPath = "/Case Studies";
  const message = "Case Studies";

  return (
    <>
      <Breadcrumb path={currentPath} message={message} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Damage />
          </Grid>
          <Grid item xs={12} md={5}>
            <Quote />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default About;
