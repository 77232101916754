import React from "react";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Box } from "@mui/material";
import { Card } from "@mui/material";
import CustomButton from "../Common/CustomButton";
import { StudiesData } from "../../Utils/Constant";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./lots.css";

const Studies = () => {
  return (
    <Container sx={{ my: 10 }}>
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        {/* {StudiesData?.map((value, index) => ( */}
        <Grid
          item
          xs={12}
          md={10}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Card className="done" elevation={5}>
            {/* <Grid container spacing={1}> */}
            {/* <Grid item xs={12} sm={4}>
                  <img
                    src={value?.imgBlog}
                    style={{
                      width: "100%",
                    }}
                    alt="Blog Overview"
                  />
                </Grid> */}
            {/* <Grid item xs={12} sm={8}> */}
            <Typography className="building">WARNING ASBESTOS</Typography>
            {/* <Box className="timing"> */}
            <Typography className="naming" gutterBottom>
              Don't forget about the risk of disturbing Asbestos Containing
              Materials during a renovation or extension.
            </Typography>
            <Typography className="dating">
              GET AN ASBESTOS INSPECTION BEFORE YOU START A RENOVATION
            </Typography>
            {/* </Box> */}
            {/* </Grid> */}
            {/* </Grid> */}
          </Card>
        </Grid>
        {/* ))} */}
      </Grid>
    </Container>
  );
};

export default Studies;
