import React from "react";
import Button from "@mui/material/Button";

const Index = ({
  handleClick,
  btnText,
  btntype,
  buttonStyle,
  width,
  height,
  borderRadius,
  fontSize,
  fontWeight,
  border,
  marginTop,
  background,
  color,
  startIcon,
  endIcon,
  disabled,
}) => {
  return (
    <>
      <Button
        onClick={handleClick}
        variant="outlined"
        disabled={disabled}
        sx={{
          background: { background },
          color: { color },
          width: { width },
          height: { height },
          borderRadius: { borderRadius },
          fontSize: { fontSize },
          fontWeight: { fontWeight },
          border: { border },
          textTransform: "capitalize",
          textAlign: "center",
          marginTop: { marginTop },
        }}
        type={btntype}
        className={`${buttonStyle || ""}`}
      >
        {startIcon}
        {btnText}
        {endIcon}
      </Button>
    </>
  );
};

export default Index;
