import AustralianDog from "../Assets/australianDogBlog.jpeg";
import Mitigation from "../Assets/mitigationBlog.jpeg";
import Longevity from "../Assets/longevityBlog.jpeg";
import Maintenance from "../Assets/maintenanceBlog.jpeg";

export const BlogData = [
  {
    id: 1,
    imgScr: AustralianDog,
    title: "THE IMPORTANCE OF GETTING A PRE-PURCHASE BUILDING INSPECTION DONE",
    description:
      "It can often seem like a tedious task among the long checklist of ‘to do’ items when buying a home, but booking a building report",
    tag: "BUILDING INSPECTION",
  },
  {
    id: 2,
    imgScr: Mitigation,
    title: "Top 10 Tips for Termite Mitigation",
    description:
      "How to reduce the risk of termite attacks on your home/property: Trim all trees, bushes and other dense vegetation away from the house. Do not",
    tag: "TIPS",
  },
  {
    id: 3,
    imgScr: Longevity,
    title: "Top 5 Tips for Home Maintenance and Longevity! #1",
    description:
      "As the years go by and your house gets older, like anything, maintenance is key to the longevity of your property! With so many fancy",
    tag: "TIPS",
  },
  {
    id: 4,
    imgScr: Maintenance,
    title: "Top 5 Tips for Home Maintenance and Longevity! #2",
    description:
      "Fences & Gates Prolong the life of your fences by maintaining any paint on them in good condition. Ensure the pailings/sheeting and rails are free",
    tag: "TIPS",
  },
];

export const ExpoData = [
  {
    id: 1,
    title: "• Put an expert in your corner",
  },
  {
    id: 2,
    title: "• Manage your relationship with your builder",
  },
  {
    id: 3,
    title: "• Ensure you get what you have paid for",
  },
  {
    id: 4,
    title: "• Safety Hazards",
  },
  {
    id: 5,
    title: "• Suspected Non-Compliance",
  },
  {
    id: 6,
    title: "• Substandard Workmanship",
  },
  {
    id: 7,
    title: "• Incomplete Works",
  },
];

export const StudiesData = [
  {
    id: 1,
    imgBlog: AustralianDog,
    title: "THE IMPORTANCE OF GETTING A PRE-PURCHASE BUILDING INSPECTION DONE",
    description:
      "It can often seem like a tedious task among the long checklist of ‘to do’ items whe buying a home, but booking a building report",
    bloggerName: "Waza Smith",
    blogDate: ". March 1, 2022",
  },
  {
    id: 2,
    imgBlog: Mitigation,
    title: "Top 10 Tips For Termite Mitigation",
    description:
      "How to reduce the risk of termite attacks on your home/property: Trim all trees, bushes and other dense vegetation away from the house. Do not",
    bloggerName: "Waza Smith",
    blogDate: ". January 16, 2022",
  },
];

export const BuildingData = [
  {
    id: 1,
    packageDetails: "Thorough Inspection",
  },
  {
    id: 2,
    packageDetails: " Serious Safety Hazards",
  },
  {
    id: 3,
    packageDetails: "Inside Condition - Major Defects",
  },
  {
    id: 4,
    packageDetails: "Outside Condition - Major Defects",
  },
  {
    id: 5,
    packageDetails: "Minor Defects",
  },
  {
    id: 6,
    packageDetails: "Timber Pest Attack",
  },
  {
    id: 7,
    packageDetails: "Conditions Conducive to Timber Pest Attack",
  },
  {
    id: 8,
    packageDetails: "Major Safety Hazards",
  },
  {
    id: 9,
    packageDetails: "Property Report Summary",
  },
  {
    id: 10,
    packageDetails: "Timber Pest Report Summary",
  },
  {
    id: 11,
    packageDetails: "Certification",
  },
  {
    id: 12,
    packageDetails: "Thorough Inspection",
  },
];

export const PremiumData = [
  {
    id: 1,
    packageDetails: "Building and Timber Pest Inspection (Our Popular Service)",
  },
  {
    id: 2,
    packageDetails: "Thorough Inspection",
  },
  {
    id: 3,
    packageDetails: "Serious Safety Hazards",
  },
  {
    id: 4,
    packageDetails: "Inside Conditions - Major Defects",
  },
  {
    id: 5,
    packageDetails: "Outside Condition - Major Defects",
  },
  {
    id: 6,
    packageDetails: "Minor Defects",
  },
  {
    id: 7,
    packageDetails: "Timber Pest Attack",
  },
  {
    id: 8,
    packageDetails: "Conditions Conducive to Timber Pest Attack",
  },
  {
    id: 9,
    packageDetails: "Major Safety Hazards",
  },
  {
    id: 10,
    packageDetails: "Electrical*",
  },
  {
    id: 11,
    packageDetails: "Asbestos*",
  },
  {
    id: 12,
    packageDetails: "Air Conditioning*",
  },
  {
    id: 13,
    packageDetails: "Gas*",
  },
  {
    id: 14,
    packageDetails: "Walk through video",
  },
  {
    id: 15,
    packageDetails: "Property Report Summary",
  },
  {
    id: 16,
    packageDetails: "Timber Pest Report Summary",
  },
  {
    id: 17,
    packageDetails: "Certification",
  },
];

export const HandOverData = [
  {
    id: 1,
    packageDetails: "Thorough Inspection",
  },
  {
    id: 2,
    packageDetails: "Serious Safety Hazards",
  },
  {
    id: 3,
    packageDetails: "Inside Condition - Major Defects",
  },
  {
    id: 4,
    packageDetails: "Outside Condition - Major Defects",
  },
  {
    id: 5,
    packageDetails: "Minor Defects",
  },
  {
    id: 6,
    packageDetails: "Property Report Summary",
  },
  {
    id: 7,
    packageDetails: "Certification",
  },
];

export const TimberPestData = [
  {
    id: 1,
    packageDetails: "Thorough Inspection",
  },
  {
    id: 2,
    packageDetails: "Timber Pest Attack",
  },
  {
    id: 3,
    packageDetails: "Conditions Conducive to Timber Pest Attack",
  },
  {
    id: 4,
    packageDetails: "Timber Pest Report Summary",
  },
  {
    id: 5,
    packageDetails: "Certification",
  },
  {
    id: 6,
    packageDetails: "Annual Timber Pest Inspections",
  },
];

export const TaxData = [
  {
    id: 1,
    yearWise: "$9,500",
    fiveYear: "$37,000",
    annual: "$3,515",
    houseType: "HOUSES",
    area: "SMALL",
  },
  {
    id: 2,
    yearWise: "$14,500",
    fiveYear: "$46,500",
    annual: "$5,365",
    houseType: "HOUSES",
    area: "LARGE",
  },
  {
    id: 3,
    yearWise: "$18,500",
    fiveYear: "$72,000",
    annual: "$6,845",
    houseType: "HOUSES",
    area: "ARCHITECTURAL",
  },
  {
    id: 4,
    yearWise: "$10,000",
    fiveYear: "$39,000",
    annual: "$3,700",
    houseType: "TOWN",
    area: "HOUSES",
  },
  {
    id: 5,
    yearWise: "$13,000",
    fiveYear: "$50,000",
    annual: "$4,810",
    houseType: "UNITS",
    area: "LOW RISE",
  },
  {
    id: 6,
    yearWise: "$15,000",
    fiveYear: "$58,500",
    annual: "$5,550",
    houseType: "UNITS",
    area: "HIGH RISE",
  },
];

export const PoolData = [
  {
    id: 1,
    quote: "Does gate swing back to the closed position after being opened?",
    no: "No - Replace hinges",
  },
  {
    id: 2,
    quote:
      "Does gate latch and stay closed after it returns to the closed position?",
    no: "No - Replace latch",
  },
  {
    id: 3,
    quote: "Is gate secure so that it can’t be pulled open once latched?",
    no: "No - Fix or replace latch",
  },
  {
    id: 4,
    quote:
      "Is gate secure and doesn’t open if a child bounces on the bottom rail of the gate?",
    no: "No - Fix or replace latch",
  },
  {
    id: 5,
    quote: "Are you aware of the dangers of propping the gate open?",
    no: "No - Never prop gate open",
  },
  {
    id: 6,
    quote: "Is the gap between the gate and the fence less than 100mm?",
    no: "No - Reduce the gap",
  },
];

export const FenceData = [
  {
    id: 1,
    quote: "Are all of the fence panels in place?",
    no: "No - Replace fence panels",
  },
  {
    id: 2,
    quote: "Are all of the fence panels securely attached?",
    no: "No - Secure panels",
  },
  {
    id: 3,
    quote: "Have you checked the fence for gaps or holes?",
    no: "No - Check and fix fence",
  },
  {
    id: 4,
    quote:
      "Is the distance between the bottom rail and the ground less than 100mm?",
    no: "No - Reduce the gap",
  },
  {
    id: 5,
    quote: "Have you checked for rusted, loose or missing screws?",
    no: "No - Check and replace",
  },
];

export const AroundData = [
  {
    id: 1,
    quote:
      "Have climbable objects near the pool fence been removed? i.e. chairs, ladders, trees, pot plants, BBQ’s.",
    no: "No - Store objects away from fence",
  },
  {
    id: 2,
    quote:
      "Are toys always removed from the pool and stored securely after use?",
    no: "No - Store toys securely",
  },
  {
    id: 3,
    quote:
      "Have trees or shrubs near the fence been trimmed so that children can’t use them to climb the fence?",
    no: "No - Trim trees and shrubs",
  },
];

export const GrateData = [
  {
    id: 1,
    quote:
      "Are all of the pool’s suction fittings and plumbing grates/covers securely in place and in good condition?",
    no: "No - Secure loose fittings and replace broken or missing fittings",
  },
  {
    id: 2,
    quote:
      "Do all pool users (including supervising adults) know where and how to switch off the pool/spa pump system in case of an emergency?",
    no: "No - Show all people where to switch off the pump prior to using the pool",
  },
  {
    id: 3,
    quote:
      "When using the pool do all people with long hair have it tied back securely in a bun or plait or are they wearing a swimming cap?",
    no: "No - Long hair should be tied back or under a cap",
  },
];

export const EmergencyData = [
  {
    id: 1,
    quote:
      "Do you or your family members have up to date Resuscitation and First Aid skills?",
    no: "No - Enrol in a CPR or First Aid course",
  },
  {
    id: 2,
    quote: "Does your house have a First Aid Kit handy?",
    no: "No - Buy a First Aid Kit",
  },
  {
    id: 3,
    quote:
      "Is there a current Resuscitation Sign displayed prominently in the pool area?",
    no: "No - Buy and install a Resuscitation Sign",
  },
];

export const ChemicalData = [
  {
    id: 1,
    quote:
      "Are pool chemicals stored securely, out of view and out of reach of children?",
    no: "No - Always store chemicals out of reach of children",
  },
  {
    id: 2,
    quote:
      "Do you use protective equipment (gloves, goggles) when handling pool chemicals?",
    no: "No - Purchase and use appropriate protective equipment",
  },
  {
    id: 3,
    quote: "Do you, when using pool chemicals, add the chemical to water?",
    no: "No - Always add chemicals to water NOT water into chemicals",
  },
  {
    id: 4,
    quote:
      "Do you use clean or original chemical containers when mixing chemicals to avoid vapours from incompatible chemicals?",
    no: "No - Always use clean or original chemical containers to mix chemicals",
  },
  {
    id: 5,
    quote:
      "Do you read the Safety Directions and Directions For Use label on the pool chemicals before use?",
    no: "No - Always read the instructions before using",
  },
];

export const ElectricData = [
  {
    id: 1,
    quote: "Do you have a RCD or RCCB on power supply?",
    no: "No - Have a RCD or RCCB installed",
  },
];

export const SuperData = [
  {
    id: 1,
    quote:
      "When using the pool are you always within arm’s reach of any child under 5 years of age?",
    no: "No - Always supervise your child around water",
  },
  {
    id: 2,
    quote:
      "Are you aware of the dangers of leaving older children to supervise younger children when they are using the pool?",
    no: "No - An adult should always be supervising children",
  },
  {
    id: 3,
    quote:
      "During parties is an adult designated ‘child supervisor’ duties around the pool?",
    no: "No - An adult should always be supervising children",
  },
];
