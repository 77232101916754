import { Container, Typography } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./audit.css";

const Sampling = () => {
  return (
    <Container sx={{ my: 6 }}>
      <Typography className="arrange" gutterBottom>
      A Timber Pest Inspection is a thorough examination of a property to assess and identify
potential issues related to timber-destroying pests. Timber pests, commonly called wood-
destroying organisms, include termites (white ants), borers, and wood decay fungi. The
primary purpose of this inspection is to evaluate the condition of timber structures within
and around a property, aiming to detect any signs of pest infestation or damage.
      </Typography>
  
      <Typography className="ours" gutterBottom mt={3}>
      Here&#39;s an overview of what a Timber Pest Inspection typically involves:
      </Typography>

      <Typography className="taken">Visual Inspection</Typography>
      <Typography className="arrange" gutterBottom>
    The inspector carefully examines the property’s interior and exterior,
including accessible roof spaces and subfloor areas. Special attention is given to timber
elements such as framing, flooring, walls, ceilings, and wooden fixtures.
      </Typography>

      <Typography className="taken">Identification of Pests</Typography>
      <Typography className="arrange" gutterBottom>
      The inspector looks for evidence of various timber pests, such as
termite mud tubes, exit holes from borers, and signs of fungal decay. They may use
specialized tools like moisture meters and thermal imaging cameras to detect hidden issues.
      </Typography>

      <Typography className="taken">Assessment of Damage</Typography>
      <Typography className="arrange" gutterBottom>
      If pests are present, the inspector assesses the extent of the
damage caused to the timber structures. This evaluation helps determine the severity of the
infestation and the necessary remedial actions.
      </Typography>

      <Typography className="taken">Recommendations and Report</Typography>
      <Typography className="arrange" gutterBottom>
      A detailed report is provided to the property owner,
outlining the findings of the inspection. The report includes information on any identified
timber pests, the extent of damage, and recommended treatment and preventive
measures.
      </Typography>
      
      <Typography className="taken">Prevention and Maintenance Advice</Typography>
      <Typography className="arrange" gutterBottom>
      The inspector may offer advice on ways to prevent
future pest infestations and recommend regular maintenance practices to protect the
property&#39;s timber components.
      </Typography>
      <Typography className="arrange" gutterBottom mt={5}>
      Timber Pest Inspections are crucial for homeowners, property buyers, and real estate
professionals, as they help in early detection of pest-related issues, preventing extensive
damage and costly repairs. Regular inspections, especially in regions prone to termite
activity, are essential to safeguard the structural integrity of buildings.
      </Typography>
     
    </Container>
  );
};

export default Sampling;
