import { Typography, Box, Container, Grid } from "@mui/material";
import React from "react";
import "./pool.css";
import {
  AroundData,
  ChemicalData,
  ElectricData,
  EmergencyData,
  FenceData,
  GrateData,
  PoolData,
  SuperData,
} from "../../Utils/Constant";

const PoolSafety = () => {
  return (
    <Container sx={{mb:3}}>
      <Typography className="solute">
        The OneStop Service Sa Ultimate Checklist
      </Typography>
      <Typography className="remind">
        PoolSS wants to remind every pool owner to check, fix and maintain their
        home pool and surrounds to eliminate child drowning. Drowning deaths are
        preventable where home pool fencing and effective supervision can reduce
        tragic drowning deaths to zero.
      </Typography>

      <Typography className="gate" mt={3}>
        Swimming Pool Gate
      </Typography>
      <Box className="must">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography className="ground" gutterBottom>
              • Must open outward from pool
            </Typography>
            <Typography className="ground" gutterBottom>
              • Must be self closing and self latching
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography className="ground" gutterBottom>
              • Latch must be more than 1.5m from the ground
            </Typography>
            <Typography className="ground" gutterBottom>
              • Must latch shut on the first swing
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {PoolData?.map((value) => (
        <Grid
          container
          spacing={2}
          sx={{
            padding: "8px !important",
            mt: -1,
            ml: -1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              backgroundColor: "#dfecf2",
              pt: "8px !important",
            }}
          >
            <Typography mr={2} gutterBottom>
              {value?.quote}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="safe">
              <Box
                sx={{
                  width: "20px !important",
                  border: "2px solid #29b2d2 !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>Yes</Typography>
            </Box>
            <Box className="safe">
              <Box
                sx={{
                  border: "2px solid #29b2d2 !important",
                  width: "20px !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>{value?.no}</Typography>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Typography className="gate" mt={3}>
        Swimming Pool Fence
      </Typography>
      <Box className="must">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography className="ground" gutterBottom>
              • Secure and in good working order
            </Typography>
            <Typography className="ground" gutterBottom>
              • No more than 100mm from the ground
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography className="ground" gutterBottom>
              • Should be at least 1.2m high
            </Typography>
            <Typography className="ground" gutterBottom>
              • No vertical gaps more than 100mm apart
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {FenceData?.map((value) => (
        <Grid
          container
          spacing={2}
          sx={{
            padding: "8px !important",
            mt: -1,
            ml: -1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              backgroundColor: "#dfecf2",
              pt: "8px !important",
            }}
          >
            <Typography mr={2} gutterBottom>
              {value?.quote}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="safe">
              <Box
                sx={{
                  width: "20px !important",
                  border: "2px solid #29b2d2 !important",
                  height: "20px !important",
                  mr: 1,
                }}
              ></Box>
              <Typography>Yes</Typography>
            </Box>
            <Box className="safe">
              <Box
                sx={{
                  border: "2px solid #29b2d2 !important",
                  width: "20px !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>{value?.no}</Typography>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Typography className="gate" mt={3}>
        Around the Swimming Pool Fence
      </Typography>
      <Box className="must">
        <Typography className="ground" gutterBottom>
          • Pool aids and toys should be stored securely and out of view
        </Typography>
        <Typography className="ground" gutterBottom>
          • Objects that could be used to climb the fence should be removed from
          the area
        </Typography>
      </Box>

      {AroundData?.map((value) => (
        <Grid
          container
          spacing={2}
          sx={{
            padding: "8px !important",
            mt: -1,
            ml: -1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              backgroundColor: "#dfecf2",
              pt: "8px !important",
            }}
          >
            <Typography mr={2} gutterBottom>
              {value?.quote}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="safe">
              <Box
                sx={{
                  width: "20px !important",
                  border: "2px solid #29b2d2 !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>Yes</Typography>
            </Box>
            <Box className="safe">
              <Box
                sx={{
                  border: "2px solid #29b2d2 !important",
                  width: "20px !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>{value?.no}</Typography>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Box className="own">
        <Typography className="consider" mt={4}>
          <span className="claim"> Disclaimer:</span> This checklist does not
          substitute for a pool inspection and is for educational purposes only.
          Pool owners should consider getting a professional assessment of their
          pool’s compliance.
        </Typography>
        <a
          href="https://onestopservicesa.com.au/"
          target="_blank"
          className="match"
        >
          <Typography className="claim">onestopservicesa.com.au</Typography>
        </a>
      </Box>

      <Typography className="gate" mt={3}>
        Pump, Grates and Suction
      </Typography>
      <Box className="must">
        <Typography className="ground" gutterBottom>
          Ensure that no fitting is broken or missing
        </Typography>
      </Box>

      {GrateData?.map((value) => (
        <Grid
          container
          spacing={2}
          sx={{
            padding: "8px !important",
            mt: -1,
            ml: -1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              backgroundColor: "#dfecf2",
              pt: "8px !important",
            }}
          >
            <Typography mr={2} gutterBottom>
              {value?.quote}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="safe">
              <Box
                sx={{
                  width: "20px !important",
                  border: "2px solid #29b2d2 !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>Yes</Typography>
            </Box>
            <Box className="safe">
              <Box
                sx={{
                  border: "2px solid #29b2d2 !important",
                  width: "20px !important",
                  height: "20px !important",
                  boxSizing: "border-box !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>{value?.no}</Typography>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Typography className="gate" mt={3}>
        Emergency Preparation
      </Typography>
      <Box className="must">
        <Typography className="ground" gutterBottom>
          • Up to date CPR and First Aid Skills
        </Typography>
        <Typography className="ground" gutterBottom>
          • Resuscitation Sign prominent in pool area
        </Typography>
      </Box>

      {EmergencyData?.map((value) => (
        <Grid
          container
          spacing={2}
          sx={{
            padding: "8px !important",
            mt: -1,
            ml: -1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              backgroundColor: "#dfecf2",
              pt: "8px !important",
            }}
          >
            <Typography mr={2} gutterBottom>
              {value?.quote}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="safe">
              <Box
                sx={{
                  width: "20px !important",
                  border: "2px solid #29b2d2 !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>Yes</Typography>
            </Box>
            <Box className="safe">
              <Box
                sx={{
                  border: "2px solid #29b2d2 !important",
                  width: "20px !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>{value?.no}</Typography>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Typography className="gate" mt={3}>
        Chemicals
      </Typography>
      <Box className="must">
        <Typography className="ground" gutterBottom>
          Should be stored securely, out of view and out of children’s reach
        </Typography>
      </Box>

      {ChemicalData?.map((value) => (
        <Grid
          container
          spacing={2}
          sx={{
            padding: "8px !important",
            mt: -1,
            ml: -1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              backgroundColor: "#dfecf2",
              pt: "8px !important",
            }}
          >
            <Typography mr={2} gutterBottom>
              {value?.quote}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="safe">
              <Box
                sx={{
                  width: "20px !important",
                  border: "2px solid #29b2d2 !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>Yes</Typography>
            </Box>
            <Box className="safe">
              <Box
                sx={{
                  border: "2px solid #29b2d2 !important",
                  width: "20px !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>{value?.no}</Typography>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Typography className="gate" mt={3}>
        Electricity
      </Typography>
      <Box className="must">
        <Typography className="ground" gutterBottom>
          • Electricity and water do not mix.
        </Typography>
        <Typography className="ground" gutterBottom>
          • A Residual Current Device (RCD) or Residual Current Circuit Breaker
          (RCCB) can save lives
        </Typography>
      </Box>

      {ElectricData?.map((value) => (
        <Grid
          container
          spacing={2}
          sx={{
            padding: "8px !important",
            mt: -1,
            ml: -1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              backgroundColor: "#dfecf2",
              pt: "8px !important",
            }}
          >
            <Typography mr={2} gutterBottom>
              {value?.quote}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="safe">
              <Box
                sx={{
                  width: "20px !important",
                  border: "2px solid #29b2d2 !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>Yes</Typography>
            </Box>
            <Box className="safe">
              <Box
                sx={{
                  border: "2px solid #29b2d2 !important",
                  width: "20px !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>{value?.no}</Typography>
            </Box>
          </Grid>
        </Grid>
      ))}

      <Typography className="gate" mt={3}>
        Appropriate Supervision
      </Typography>
      <Box className="must">
        <Typography className="ground" gutterBottom>
          Adult supervision in combination with pool fencing is the most
          effective method of preventing your child from drowning
        </Typography>
      </Box>

      {SuperData?.map((value) => (
        <Grid
          container
          spacing={2}
          sx={{
            padding: "8px !important",
            mt: -1,
            ml: -1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              backgroundColor: "#dfecf2",
              pt: "8px !important",
            }}
          >
            <Typography mr={2} gutterBottom>
              {value?.quote}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box className="safe">
              <Box
                sx={{
                  width: "20px !important",
                  border: "2px solid #29b2d2 !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>Yes</Typography>
            </Box>
            <Box className="safe">
              <Box
                sx={{
                  border: "2px solid #29b2d2 !important",
                  width: "20px !important",
                  height: "20px !important",
                  mr: 2,
                }}
              ></Box>
              <Typography>{value?.no}</Typography>
            </Box>
          </Grid>
        </Grid>
      ))}
{/* 
      <Typography className="prevent">
        Visit
        <a
          href="https://poolsafetysolutions.com.au/"
          target="_blank"
          className="drown"
        >
          <span className="claim"> poolsafetysolutions.com.au </span>
        </a>
        and learn more about how to prevent children from drowning.
      </Typography> */}
    </Container>
  );
};

export default PoolSafety;
