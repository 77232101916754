import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import React from "react";
import TermiteCase from "../../Assets/termiteCase.jpeg";
import PropertyCase from "../../Assets/propertyCase.jpeg";
import CondusiveCase from "../../Assets/conduciveCase.jpeg";
import "./lost.css";

const Damage = () => {
  return (
    <Container sx={{ my: 10 }} className="sure">
      {/* <Typography className="inspect" variant="h4" gutterBottom>
        Termite Damage
      </Typography>
      <img src={TermiteCase} width="100%" alt="About Detail" />
      <Typography className="inspect" variant="h4" gutterBottom>
        Termites Located On Property
      </Typography>
      <img src={PropertyCase} width="100%" alt="About Detail" />
      <Typography className="inspect" variant="h4" gutterBottom>
        Conducive Conditions to Termites
      </Typography>
      <img src={CondusiveCase} width="100%" alt="About Detail" /> */}
      <Typography className="construct">
        NEW CONSTRUCTION INSPECTIONS
      </Typography>
      <Typography className="builder">NEW HOME BUILDER</Typography>
      <Typography className="plan" gutterBottom>
        ARE YOU PLANNING BUILDING A NEW HOME
      </Typography>
      <Typography className="complete" gutterBottom>
        Then let us make sure your complete build is done at current Australian
        standards.
      </Typography>
      <Typography className="frame">
        ▪ &nbsp; AS 1684 Residential Timber Framed Construction NCC
      </Typography>
      <Typography className="frame">
        ▪ &nbsp; Building Code of Australia
      </Typography>
      <Typography className="frame">
        ▪ &nbsp; HIA Standards and Tolerances
      </Typography>
      <Typography className="plan">
        WHY IS THIS STAGE INSPECTION IMPORTANT?
      </Typography>
      <Typography className="frame">
        <span className="identify">To identify</span> any Safety Hazards.{" "}
        <span className="identify">To identify</span> any NCC/AS Non-Compliance
        Suspected work. <span className="identify">To identify</span> any
        Incomplete work or Substandard or Damaged building materials.
      </Typography>
      <Typography className="frame">
        <span className="identify">To Make sure</span> all Building work is done
        as per Contracts and Specifications.
      </Typography>
      <Typography className="plan">WHAT IS THE COST?</Typography>
      <Typography className="frame" gutterBottom>
        The option includes 5 Stages.
      </Typography>
      <Typography className="frame" gutterBottom>
        1. &nbsp; <span className="pour">Pre-Pour.</span> $220 We check the
        Trenching measurements ,vapor barrier & Reinforcing installation &
        plumbing pipework as per specifications.
      </Typography>
      <Typography className="frame" gutterBottom>
        2. &nbsp; <span className="pour">Slab and frames.</span> We check the
        slab, the wall and roof framing, bracing and tie-downs for compliance
        with Australian standards plans and specifications and ensure good
        quality artistry. $430
      </Typography>
      <Typography className="frame" gutterBottom>
        3. &nbsp; <span className="pour">Wall and roof cladding.</span> Where we
        check the external walls and roofing for compliance Australian with
        standards, plans, and specifications and ensure good quality artistry,
        we also check electrical, air-conditioning and plumbing preliminaries at
        this stage. $430
      </Typography>{" "}
      <Typography className="frame" gutterBottom>
        4. &nbsp; <span className="pour">Lockup.</span> We check internal
        claddings, second-fix items, and waterproofing before tile work to
        comply with Australian standards, plans and specifications and ensure
        good artistry. $330
      </Typography>
      <Typography className="frame" gutterBottom>
        5. &nbsp; <span className="pour">Final Inspection.</span> We check all
        finishes inside and out for compliance with Australian standards, plans
        and specifications and ensure good quality artistry. $430 Total $1840.00
        incl GST
      </Typography>
      <Typography className="short" mt={3}>
        Available on short notice 7 to 7 / 7 days a week
      </Typography>
    </Container>
  );
};

export default Damage;
