import React from "react";

import BuyerAgents from "../Components/BuyerAgents";
import ServiceBanner from "../Components/ServiceBanner";
import Breadcrumb from "../Components/Common/BreadCrumb";


const InspectionServices = () => {
  const currentPath = "Pre-Purchase Building Inspections";
  const message = "Pre-Purchase Building Inspections";
  return (
    <div>
    <Breadcrumb path={currentPath} message={message} />
      {/* <ContactForm /> */}
      {/* <Commitment /> */}
      <BuyerAgents />
      <ServiceBanner />
      {/* <NewestSoftware /> */}
      {/* <DefectsAndTestWorks/> */}
      {/* <Testimonial /> */}
    </div>
  );
};

export default InspectionServices;
