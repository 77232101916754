import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import { ExpoData } from "../../Utils/Constant";
import CustomButton from "../Common/CustomButton";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./expo.css";
import { Link } from "react-router-dom";

const Experience = () => {
  return (
    <>
      <Box className="expBg">
        <Container>
          <Grid container spacing={2} className="fully">
            <Grid item xs={12} md={6} className="qbcc">
              <Typography className="over" gutterBottom>
                In most cases, the homeowner relies upon the builder and
                contractors to complete the building work to an acceptable
                standard.
              </Typography>
              <Typography className="expert" variant="h2" gutterBottom>
                GET AN INSPECTION DONE BEFORE YOUR NEXT STAGE PAYMENT WHAT YOU
                GET:
              </Typography>
              <Typography className="count" gutterBottom>
                Report emailed to you within 24 hours covering:
              </Typography>
              <Link to="/contact" className="routing">
                <CustomButton
                  btnText="Learn More About Us"
                  buttonStyle="learn"
                  endIcon={<ArrowRightAltIcon />}
                />
              </Link>
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                {ExpoData?.map((value, index) => (
                  <Grid item xs={12} sm={4} className="corner">
                    <Box className="inspection" key={index}>
                      <Typography className="benefit" variant="h5" gutterBottom>
                        {value?.title}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* <Box>
        <Link to="/contact" className="routing">
          <CustomButton
            btnText="Book Your Old Building Inspection Now!"
            buttonStyle="old"
          />
        </Link>
      </Box> */}
    </>
  );
};

export default Experience;
