import React from "react";
import FaqQuestions from "../Components/FaqQuestions";
import Breadcrumb from "../Components/Common/BreadCrumb";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import Quote from "../Components/Quote";

const Faqs = () => {
  const currentPath = "/FAQ's";
  const message = "FAQ's";

  return (
    <>
      <Breadcrumb path={currentPath} message={message} />
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <FaqQuestions />
          </Grid>
          <Grid item xs={12} md={5} mb={5}>
            <Quote />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Faqs;
