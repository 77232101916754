import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import Logo from "../../../Assets/ossLogo.jpeg";
import "./nav.css";
import { Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const handleClick = () => {
  window.scrollTo(0, 0);
};

const drawerWidth = 300;
function DrawerAppBar(props) {
  const [smoking, setSmoking] = useState(false);
  const [weight, setWeight] = useState(false);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option) => {
    setSelectedOption(option);
    handleMenuClose();
  };

  const [anchorElSm, setAnchorElSm] = useState(null);
  const [selectedOptionOne, setSelectedOptionOne] = useState("");

  const handleMenuClickOne = (event) => {
    setAnchorElSm(event.currentTarget);
  };

  const handleMenuCloseOne = () => {
    setAnchorElSm(null);
  };

  const handleMenuItemClickOne = (option) => {
    setSelectedOptionOne(option);
    handleMenuCloseOne();
  };

  const drawer = (
    <Box
      // onClick={handleDrawerToggle}
      sx={{ textAlign: "center", background: "#fff !important", zIndex: "0" }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link
          to="/"
          style={{ textDecoration: "none" }}
          onClick={() => {
            handleClick();
            handleDrawerToggle();
          }}
        >
          <Box sx={{ height: "77px", mr: 1 }}>
            <img src={Logo} alt="logo" width="200px" height="100%" />
          </Box>
        </Link>

        <CloseIcon // Use the Close icon here
          sx={{
            fontSize: "40px !important",
            color: "#34A9E2",
            display: {
              xs: "block !important",
              lg: "none !important",
            },
            cursor: "pointer",
          }}
          onClick={handleDrawerToggle}
        />
      </Box>
      <Box className="navItems">
        <Link to="/" style={{ textDecoration: "none" }}>
          <Button
            onClick={() => {
              setWeight(true);
              setSmoking(false);
              handleDrawerToggle();
            }}
          >
            <Typography
              className="home"
              sx={{
                color: weight ? "black !important" : "black !important",
                my: 1,
              }}
            >
              Home
            </Typography>
          </Button>
        </Link>
        <Link to="/about" style={{ textDecoration: "none" }}>
          <Button
            onClick={() => {
              setSmoking(true);
              setWeight(false);
              handleDrawerToggle();
            }}
          >
            <Typography
              className="home"
              sx={{
                color: smoking ? "black !important" : "black !important",
                my: 1,
              }}
            >
              About
            </Typography>
          </Button>
        </Link>
        <Button
          aria-controls="dropdown-menu"
          aria-haspopup="true"
          onClick={handleMenuClickOne}
          color="inherit"
          endIcon={<ArrowDropDownIcon />}
          sx={{
            fontSize: "13px !important",
            fontWeight: "600 !important",
            fontFamily: "Lusitana !important",
            my: 1,
          }}
          className="checker"
        >
          Services
        </Button>
        <Menu
          id="dropdown-menu"
          anchorEl={anchorElSm}
          open={Boolean(anchorElSm)}
          onClose={handleMenuCloseOne}
          sx={{ width: "100%" }}
        >
          <MenuItem
            onClick={() => {
              handleMenuCloseOne();
              handleDrawerToggle();
            }}
          >
            <Link to="/services/tax-depreciation" className="awareness">
              Tax Depreciation
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuCloseOne();
              handleDrawerToggle();
            }}
          >
            <Link to="/services/asbestos-awareness" className="awareness">
              Asbestos Awareness
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuCloseOne();
              handleDrawerToggle();
            }}
          >
            <Link to="/services/pool-safety" className="awareness">
              Pool Safety
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuCloseOne();
              handleDrawerToggle();
            }}
          >
            <Link to="/services/timber-pest-inspections" className="awareness">
            Timber Pest Inspections
            </Link>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuCloseOne();
              handleDrawerToggle();
            }}
          >
            <Link to="/services/inspection" className="awareness">
            Pre-Purchase Building Inspections 
            </Link>
          </MenuItem>
        </Menu>
        <Link to="/case-studies" style={{ textDecoration: "none" }}>
          <Button
            onClick={() => {
              handleDrawerToggle();
            }}
          >
            <Typography
              className="home"
              sx={{
                color: smoking ? "black !important" : "black !important",
                my: 1,
              }}
            >
              Case Studies &nbsp;
            </Typography>
          </Button>
        </Link>
        <Link to="/faqs" style={{ textDecoration: "none" }}>
          <Button
            onClick={() => {
              handleDrawerToggle();
            }}
          >
            <Typography
              className="home"
              sx={{
                color: smoking ? "black !important" : "black !important",
                my: 1,
              }}
            >
              FAQ's &nbsp;
            </Typography>
          </Button>
        </Link>
        <Link to="/contact" style={{ textDecoration: "none" }}>
          <Button
            onClick={() => {
              handleDrawerToggle();
            }}
          >
            <Typography
              className="home"
              sx={{
                color: smoking ? "black !important" : "black !important",
                my: 1,
              }}
            >
              Contact &nbsp;
            </Typography>
          </Button>
        </Link>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#fff !important",
      }}
    >
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "#fff !important",
          position: "relative",
          padding: "5px 0px !important",
        }}
      >
        <Container
          sx={{ maxWidth: { xs: "100% !important", lg: "1200px !important" } }}
        >
          <Toolbar
            sx={{
              padding: "0px !important",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: { xs: "block !important", lg: "none !important" },
                padding: "3px 0px 0px !important",
              }}
            >
              <img src={Logo} alt="logo" width="220px" height="75px" />
            </Box>
            <Link
              to="/"
              style={{ textDecoration: "none" }}
              onClick={handleClick()}
            >
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: { lg: "none" },
                  padding: "0px !important",
                }}
              >
                <MenuIcon
                  sx={{
                    fontSize: "40px !important",
                    color: "#34A9E2",
                    display: { xs: "block !important", lg: "none !important" },
                  }}
                />
              </IconButton>
            </Link>
            <Box
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", lg: "flex" } }}
            >
              <Link
                to="/"
                style={{ textDecoration: "none" }}
                onClick={handleClick()}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "left !important",
                    alignItems: "center",
                    flexDirection: "column",
                    padding: "5px 0px",
                  }}
                >
                  <img src={Logo} alt="logo" width="220px" height="75px" />
                </Box>
              </Link>
            </Box>
            <Box sx={{ display: { xs: "none", lg: "block" } }}>
              <Box
                sx={{
                  display: "flex !important",
                  justifyContent: "center !important",
                  alignItems: "center !important",
                }}
              >
                <Link
                  to="/"
                  style={{ textDecoration: "none" }}
                  onClick={handleClick()}
                >
                  <Button
                    onClick={() => {
                      setWeight(true);
                      setSmoking(false);
                    }}
                  >
                    <Typography
                      className="home"
                      sx={{
                        color: weight ? "black !important" : "black !important",
                      }}
                    >
                      Home
                    </Typography>
                  </Button>
                </Link>
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <Button
                    onClick={() => {
                      setSmoking(true);
                      setWeight(false);
                    }}
                  >
                    <Typography
                      className="home"
                      sx={{
                        color: smoking
                          ? "black !important"
                          : "black !important",
                      }}
                    >
                      About &nbsp;
                    </Typography>
                  </Button>
                </Link>
                <Link  className="aware">
                  <Button
                    aria-controls="dropdown-menu"
                    aria-haspopup="true"
                    onClick={handleMenuClick}
                    color="inherit"
                    endIcon={<ArrowDropDownIcon />}
                    sx={{
                      fontSize: "13px !important",
                      fontWeight: "600 !important",
                      fontFamily: "Lusitana !important",
                    }}
                    className="checker"
                  >
                    Services
                  </Button>
                </Link>
                <Menu
                  id="dropdown-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                >
                   <MenuItem onClick={handleMenuClose}>
                    <Link to="/services/inspection" className="awareness">
                    Pre-Purchase Building Inspections
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/services/timber-pest-inspections" className="awareness">
                    Timber Pest Inspections 
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/services/new-construction-stage" className="awareness">
                    New Construction Stage Inspections
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/services/tax-depreciation" className="awareness">
                      Tax Depreciation
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link
                      to="/services/asbestos-awareness"
                      className="awareness"
                    >
                  Asbestos Inspection & Register
                    </Link>
                  </MenuItem>
                  <MenuItem onClick={handleMenuClose}>
                    <Link to="/services/pool-safety" className="awareness">
                    Pool Compliance Certification
                    </Link>
                  </MenuItem>
               
               
                </Menu>
                <Link to="/case-studies" style={{ textDecoration: "none" }}>
                  <Button>
                    <Typography
                      className="home"
                      sx={{
                        color: smoking
                          ? "black !important"
                          : "black !important",
                      }}
                    >
                      Case Studies &nbsp;
                    </Typography>
                  </Button>
                </Link>
                <Link to="/faqs" style={{ textDecoration: "none" }}>
                  <Button
                    onClick={() => {
                      setSmoking(true);
                      setWeight(false);
                    }}
                  >
                    <Typography
                      className="home"
                      sx={{
                        color: smoking
                          ? "black !important"
                          : "black !important",
                      }}
                    >
                      FAQ's &nbsp;
                    </Typography>
                  </Button>
                </Link>
                <Link to="/contact" style={{ textDecoration: "none" }}>
                  <Button>
                    <Typography
                      className="home"
                      sx={{
                        color: smoking
                          ? "black !important"
                          : "black !important",
                      }}
                    >
                      Contact &nbsp;
                    </Typography>
                  </Button>
                </Link>
                {/* <Link to="/book-online">
                  <CustomButton
                    btnText="&nbsp; Book Online"
                    buttonStyle="session"
                  />
                </Link> */}
              </Box>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              backgroundColor: "#fff !important",
              padding: "20px !important",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{ display: "flex", justifyContent: "start", alignItems: "center" }}
      >
        <Toolbar sx={{ padding: "0px !important" }} />
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  window: PropTypes.func,
};

export default DrawerAppBar;
