import React from "react";
import { Link } from "react-router-dom";
import "./bread.css";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { Container } from "@mui/material";

const Breadcrumb = ({ path, message }) => {
  const segments = path.split("/").filter((segment) => segment !== "");

  return (
    <nav className="breadItems">
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={8} lg={9}>
            <Typography variant="h4" className="messaging">
              {message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} className="crumb">
            <ul className="breadcrumb items">
              <li>
                <Link to="/" className="paths">
                  You are here //{" "}
                  <span style={{ color: "#34A9E2" }}> Home</span> &nbsp;
                </Link>
              </li>
              {segments.map((segment, index) => (
                <li key={index}>
                  <Link
                    to={`/${segments.slice(0, index + 1).join("/")}`}
                    className="paths segment"
                  >
                    - &nbsp; {segment}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </Container>
    </nav>
  );
};

export default Breadcrumb;
