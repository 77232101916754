import React from "react";
import Sampling from "../Components/Mould";
import ServiceBanner from "../Components/ServiceBanner";
import Breadcrumb from "../Components/Common/BreadCrumb";


const TimberPest = () => {
  const currentPath = "Timber Pest Inspections";
  const message = "Timber Pest Inspections";
  return (
    <div>
    <Breadcrumb path={currentPath} message={message} />
      <Sampling />
      <ServiceBanner />
    </div>
  );
};

export default TimberPest;
