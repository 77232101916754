import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import React from "react";
import ProductReviews from "../../Assets/productReviews.webp";
import CustomButton from "../Common/CustomButton";
import "./store.css";

const Story = () => {
  return (
    <Container sx={{ mt: 8, mb: { xs: 0, md: 8 } }}>  

      {/* <Typography className="best">
        <span style={{ color: "#204163" }} gutterBottom>
          The Best
        </span>{" "}
        Home <br /> Inspection Company 2023
      </Typography>
      <Typography className="great" gutterBottom>
        We are thrilled to receive this great award from{" "}
        <a
          href="https://www.productreview.com.au/"
          target="_blank"
          className="reviews"
          rel="noreferrer"
        >
          <span style={{ color: "#34a9e2" }}>productreview.com.au</span>
        </a>
      </Typography> */}
      <Typography className="satisfy">
      More background on OneStop Service SA. Our business has a solid foundation,
focusing on serving the local South Australian community since 2012. The extensive
experience of our Building Consultant, spanning over 40 years and covering diverse
sectors like domestic, commercial, and industrial fields, indicates a wealth of
knowledge and expertise.
      </Typography>
      <Typography className="satisfy">The combination of various certifications, three trade qualifications, and a bachelor’s
degree in electrical engineering (BSELecEng) further showcases our team&#39;s high
level of professionalism and technical competence, assuring the quality and reliability
of your building inspection services.</Typography>
      <img src={ProductReviews} className="reward" alt="Awards" />
      <br />
      <br />

      <Typography className="service">
        {" "}
        Our Speciality is
      </Typography>
      <br />
      <Typography  variant="h5" >
      We provide many services, including but not limited to the following:
      </Typography>
      <br/>
      <Typography className="license" gutterBottom>
        {" "}
       1. Pre-purchase Building inspections, Including Timber Pest Inspections.
      </Typography>
      <Typography className="license" gutterBottom>
        {" "}
       2. New Construction Staged Building Inspections.
      </Typography>
      <Typography className="license" gutterBottom>
        {" "}
       3. Asbestos inspections, sampling and removal.
      </Typography>
      <Typography className="license" gutterBottom>
      4. Business and commercial Asbestos registers and management plans.
      </Typography>
      <Typography className="license" gutterBottom>
      5. Pool/Spa Compliance Inspections and Certification.
      </Typography>
      <Typography className="license" gutterBottom>
      6. Tax Depredation Schedules.
      </Typography>
      

      <br />
      <a
        href="https://hihello.me/p/6abc2e44-6e62-4001-ad6b-d497e8339012"
        target="_blank"
        rel="noreferrer"
      >
        <CustomButton btnText="Save Contact Details" buttonStyle="save" />
      </a>
    </Container>
  );
};

export default Story;
