import React, { useEffect } from "react";
import ContactForm from "../Components/ContactForm";
import Location from "../Components/Location";
import Breadcrumb from "../Components/Common/BreadCrumb";

const Contact = () => {
  const currentPath = "/contact";
  const message = "Contact";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Breadcrumb path={currentPath} message={message} />
      {/* <ContactForm /> */}
      <Location />
    </div>
  );
};

export default Contact;
