import React from "react";
import Taxes from "../Components/Taxes";
import ServiceBanner from "../Components/ServiceBanner";
import Breadcrumb from "../Components/Common/BreadCrumb";

const TaxDepreciations = () => {
  const currentPath = "Tax Depreciation";
  const message = "Tax Depreciation";
  return (
    <div>
    <Breadcrumb path={currentPath} message={message} />
      <Taxes />
      <ServiceBanner />
    </div>
  );
};

export default TaxDepreciations;
