/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import MailIcon from "@mui/icons-material/Mail";
import LogoTBI from "../../Assets/ossLogo.jpeg";
import "./locate.css";
import Quote from "../Quote";
import { Link } from "react-router-dom";

const Location = () => {
  return (
    <>
      <Container sx={{ my: 10 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} sx={{ mt: -10, mb: -5 }}>
            <Quote />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className="contactDetails">
              <PhoneIphoneIcon sx={{ color: "#34A9E2" }} />
              <Typography className="own">
                &nbsp; Daniel, Building Consultant &nbsp;{" "}
                <a href="tel:0499542283">+61 0499542283</a>
              </Typography>
            </Box>
            <Box className="contactDetails">
              <MailIcon sx={{ color: "#34A9E2" }} />
              <Typography>
                <a href="mailto:onestopservicesa@outlook.com">
                  &nbsp; onestopservicesa@outlook.com
                </a>
              </Typography>
            </Box>
            <Link to="/">
              <Box className="contactDetails" sx={{ mt: 5 }}>
                <img
                  src={LogoTBI}
                  style={{ width: "250px", height: "100%" }}
                  alt="OSS Logo"
                />
              </Box>
            </Link>
          </Grid>
          <Grid item xs={12} mt={5}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13921253.936977822!2d124.381922!3d-31.5860773!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46e4b01128990455%3A0x9abb8144750c85bb!2sOne%20Stop%20Service%20SA%20%7C%20Building%20Inspections%20%7C%20Cleaning%20%7C%20Adelaide%20%7C%20SA!5e0!3m2!1sen!2sin!4v1702318485878!5m2!1sen!2sin"
              width="100%"
              height="450"
              border="0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
            {/* <iframe
              src="https://www.google.com/maps/place/One+Stop+Service+SA+%7C+Building+Inspections+%7C+Cleaning+%7C+Adelaide+%7C+SA/@-31.5860773,124.381922,5z/data=!4m6!3m5!1s0x46e4b01128990455:0x9abb8144750c85bb!8m2!3d-32.0294898!4d135.0021478!16s%2Fg%2F11t1b6r1m9?entry=ttu"
              width="100%"
              height="450"
              border="0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe> */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Location;
