import React from "react";
import "./banner.css";
import { Box, Container, Grid, Typography } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import ServiceForm from "../../Components/ServiceForm";
import CustomButton from "../Common/CustomButton";

const ServiceBanner = () => {
  return (
    <Box className="bgServing">
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} md={7} lg={8}>
            <Typography className="appointment" variant="h3">
              Booking Appointment
            </Typography>
            <Typography className="solutions" variant="h3">
              Get Your Inspection done with our expert team
            </Typography>
            <Typography className="based" mb={3}>
              Get in touch with our team of experts for a personalised
              inspection and property based solutions for you.
            </Typography>
            <Typography className="based">
              Call +61 0499542283 to get a quote today!
            </Typography>
            <a href="tel:0499542283">
              <CustomButton
                btnText="CALL US NOW"
                buttonStyle="pattern"
                endIcon={<EastIcon />}
              />
            </a>
          </Grid>
          <Grid item xs={12} sm={8} md={5} lg={4}>
            <ServiceForm />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ServiceBanner;
