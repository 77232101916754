import React from 'react'
import Breadcrumb from "../Components/Common/BreadCrumb";
import Damage from "../Components/Damage";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import Quote from "../Components/Quote";
import ServiceBanner from '../Components/ServiceBanner';

const NewConstructionStage = () => {
  const currentPath = "New Construction Stage Inspections";
  const message = "New Construction Stage Inspections";
  return (
    <>
    <Breadcrumb path={currentPath} message={message} />
      <Container>
        <Grid container spacing={2}>      
          <Damage />
        </Grid>
      </Container>
          <ServiceBanner />   
      </>
  )
}

export default NewConstructionStage