import {
  Box,
  Card,
  Container,
  Grid,
  Typography,
  CardContent,
} from "@mui/material";
import React from "react";
import "./tax.css";
import { TaxData } from "../../Utils/Constant";

const TaxDepreciation = () => {
  return (
    <Container sx={{ my: 6 }}>
      <Typography className="know" gutterBottom>
        Did you know?...
      </Typography>
      <Typography className="invest" gutterBottom>
        40% of investors have never claimed
      </Typography>
      <Typography className="depart" gutterBottom>
        Tax Depreciation
      </Typography>
      <Typography className="what" gutterBottom>
        What is Tax Depreciation?
      </Typography>
      <Typography className="decline" gutterBottom>
        • Income generating assets decline in value over years
      </Typography>
      <Typography className="decline" gutterBottom>
        • The amount of the decline can be used to claim tax deduction
      </Typography>
      <Typography className="decline" gutterBottom>
        • These assets include Buildings Fixtures in the building Any capital
        work done to the building
      </Typography>
      <Typography className="what" gutterBottom>
        Who can claim tax depreciation?
      </Typography>
      <Box className="property">
        <Typography className="decline" gutterBottom>
          • PROPERTY INVESTORS
        </Typography>
        <Typography className="decline" gutterBottom>
          • BUSINESS OWNERS
        </Typography>
      </Box>
      <Typography className="depart" gutterBottom>
        Tax Depreciation
      </Typography>
      <Box className="depreciation">
        <Box className="rate" mt={1}>
          <Box
            sx={{
              backgroundColor: "#29b2d2 !important",
              width: "30px",
              height: "30px",
              mr: 2,
            }}
          ></Box>
          <Typography>Year 1</Typography>
        </Box>
        <Box className="rate" mt={1}>
          <Box
            sx={{
              backgroundColor: "#4d667a !important",
              width: "30px",
              height: "30px",
              mr: 2,
            }}
          ></Box>
          <Typography>Year 1-5</Typography>
        </Box>
        <Box className="rate" mt={1}>
          <Box
            sx={{
              backgroundColor: "#d31145 !important",
              width: "30px !important",
              height: "30px !important",
              mr: 2,
            }}
          ></Box>
          <Typography>Annual Tax Saving based on a Tax Rate of 37%</Typography>
        </Box>
      </Box>
      <Grid container spacing={2}>
        {TaxData?.map((value, index) => (
          <Grid item xs={6} sm={4} md={3} lg={2}>
            <Card elevation={5} sx={{cursor: "pointer"}}>
              <CardContent>
                <Box className="rate">
                  <Box
                    sx={{
                      backgroundColor: "#29b2d2 !important",
                      width: "30px",
                      height: "30px",
                      mr: 2,
                      mb: 2,
                    }}
                  ></Box>
                  <Typography>{value?.yearWise}</Typography>
                </Box>
                <Box className="rate">
                  <Box
                    sx={{
                      backgroundColor: "#4d667a !important",
                      width: "30px",
                      height: "30px",
                      mr: 2,
                      mb: 2,
                    }}
                  ></Box>
                  <Typography>{value?.fiveYear}</Typography>
                </Box>
                <Box className="rate">
                  <Box
                    sx={{
                      backgroundColor: "#d31145 !important",
                      width: "30px",
                      height: "30px",
                      mr: 2,
                    }}
                  ></Box>
                  <Typography>{value?.annual}</Typography>
                </Box>
                <Typography className="hType">{value?.houseType}</Typography>
                <Typography className="hArea">{value?.area}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TaxDepreciation;
