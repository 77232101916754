import React from "react";
import "./detail.css";
import { Box, Container, Grid, Typography } from "@mui/material";

const Asbestos = () => {
  return (
    <Box>
      <Container sx={{ my: 6 }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Typography className="builds" gutterBottom>
              <span className="renovate">don't play</span> renovation roulette!
            </Typography>
            <Typography gutterBottom className="contains">
              <span className="star">*</span> 1 in 3 Australian homes contain
              asbestos building materials.
            </Typography>
            <Typography gutterBottom className="contains">
              <span className="star">*</span> The risk to families is when
              asbestos building materials are disturbed or damaged and this can
              release
            </Typography>
            <Typography gutterBottom className="contains">
              <span className="star">*</span> People put themselves and their
              children at risk if they are not aware of the hazards of working
              with asbestos materials including fibro.
            </Typography>
            <Typography gutterBottom className="contains">
              <span className="star">*</span> There are legal and safety
              requirements for the management of asbestos. Follow the
              regulations!
            </Typography>
            <Typography className="contains">
              <span className="star">*</span> The cost of asbestos removal by a
              licenced professional is comparable in price to most licenced
              tradesmen including electricians, plumbers and tilers.
            </Typography>
            <Typography gutterBottom className="contains">
              <span className="star">*</span> The cost of disposal at a lawful
              landfill site is usually included when using a licenced
              professional removalist.
            </Typography>
            <Typography gutterBottom className="contains">
              <span className="star">*</span> Importantly, if you find asbestos
              in your home; <span className="dump">Don't </span> cut it!{" "}
              <span className="dump">Don't </span> drill it!{" "}
              <span className="dump">Don't </span> drop it!{" "}
              <span className="dump">Don't </span>
              sand it! <span className="dump">Don't </span> saw it!{" "}
              <span className="dump">Don't </span> scrape it!{" "}
              <span className="dump">Don't </span> scrub it!{" "}
              <span className="dump">Don't </span>
              dismantle it! <span className="dump">Don't </span> tip it!{" "}
              <spanv>Don't </spanv> waterblast it!{" "}
              <span className="dump">Don't </span> demolish it! And whatever you
              do... <span className="dump">Don't </span> dump it!”
            </Typography>

            <Box className="brick">
              <Typography className="found" gutterBottom>
                Whether a home is constructed of weatherboard, brick, fibro or
                has exterior cladding, asbestos can be found in and around homes
                built or renovated before the mid 80s.
              </Typography>
              <Typography className="found">
                Asbestos can be found in kitchens, bathrooms, laundries and
                under floor coverings such as carpets, linoleum and vinyl tiles,
                behind wall and floor tiles, in cement floors, internal and
                external walls, garages, ceilings, eaves, around hot water
                pipes, fences, extensions to homes, outdoor toilets and backyard
                sheds – it could be anywhere.
              </Typography>
            </Box>

            <Typography gutterBottom className="commitee" mt={3}>
              Visit{" "}
              <a
                href="https://asbestosawareness.com.au/"
                target="_blank"
                className="safely"
                rel="noreferrer"
              >
                asbestosawareness.com.au
              </a>{" "}
              to learn where asbestos might be found in the home and how to
              manage it safely!
            </Typography>
            <Box className="educate">
              <Typography gutterBottom className="commitee">
                Asbestos Diseases Research Institute
              </Typography>
              <Typography gutterBottom className="commitee">
                Asbestos Education Committee
              </Typography>
            </Box>

            <Typography className="types">TYPES OF HOMES</Typography>
            <Typography className="flats" gutterBottom>
              Australian Flats
            </Typography>
            <Typography className="flats" gutterBottom>
              Classic Fibro Home
            </Typography>
            <Typography className="flats" gutterBottom>
              Colonial Home
            </Typography>
            <Typography className="flats" gutterBottom>
              Colonial Pyramid Semi-Detached
            </Typography>
            <Typography className="flats" gutterBottom>
              Double Front Victorian Terrace
            </Typography>
            <Typography className="flats" gutterBottom>
              Federation Bungalow
            </Typography>
            <Typography className="flats" gutterBottom>
              Suburban Weatherboard Home
            </Typography>
            <Typography className="flats" gutterBottom>
              Traditional Suburban Brick
            </Typography>
            <Typography className="flats" gutterBottom>
              Victorian Terrace
            </Typography>
            <Typography className="flats" gutterBottom>
              Waterfall (Art Deco) Homes
            </Typography>
            <Typography className="flats" gutterBottom>
              Weekender - Beach House
            </Typography>

            <Typography className="types">ASBESTOS HAZARDS</Typography>
            <Typography className="flats" gutterBottom>
              Broken eaves
            </Typography>
            <Typography className="flats" gutterBottom>
              Holes in fibro walls
            </Typography>
            <Typography className="flats" gutterBottom>
              Damaged Tilux in bathrooms or laundries
            </Typography>
            <Typography className="flats" gutterBottom>
              Unsecure eave joins
            </Typography>
            <Typography className="flats" gutterBottom>
              Exposed asbestos after a fire
            </Typography>
            <Typography className="flats" gutterBottom>
              Falling down outhouses
            </Typography>
            <Typography className="flats" gutterBottom>
              Exposed asbestos due to peeling paint
            </Typography>
            <Typography className="flats" gutterBottom>
              Cladding hiding fibro walls
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography gutterBottom className="maintain">
              <span className="asbestos">ASBESTOS</span> CAN BE IN ANY HOME
              BUILT OR RENOVATED BEFORE 1987.
            </Typography>
            <Typography gutterBottom className="roulette">
              <span className="maintain">Are you playing</span> renovation
              roulette?
            </Typography>
            <Typography gutterBottom className="deal">
              Before you renovate or maintain your home, find out where asbestos
              could be and how to deal with it safely.{" "}
              <a
                href="https://asbestosawareness.com.au/"
                target="_blank"
                className="safely"
                rel="noreferrer"
              >
                asbestosawareness.com.au
              </a>
            </Typography>
            {/* </Grid> */}

            {/* <Grid item xs={12} sm={6}> */}
            <Box className="house">
              <Typography className="inside">Inside House</Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Backing of vinyl sheet floor
                covering
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Carpet underlay
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Cement flooring
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Compressed asbestos sheet
              </Typography>
              <Typography className="tiles">
                <span className="star">*</span> Flues to fireplaces
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Insulation below wood heater
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Internal and external
                ventilators
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Internal angle mouldings
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Internal walls & ceiling
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Kitchen splashback
              </Typography>
              <Typography className="tiles">
                <span className="star">*</span> Loose fill insulation in roof
                cavity
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> “Tilux” marble finish wall panel
              </Typography>
              <Typography className="tiles">
                <span className="star">*</span> Vinyl floor tiles
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Wall sheeting – internal
              </Typography>
            </Box>

            <Box className="house">
              <Typography className="inside">Outside House</Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Backing for electrical meter
                boards
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Dog kennels
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Downpipes
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Eaves and gables ends
              </Typography>
              <Typography className="tiles">
                <span className="star">*</span> External angle mouldings
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Fence
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Garage
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Gutters
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Insulation for hot water pipes &
                tank
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Internal and external
                ventilators
              </Typography>
              <Typography className="tiles">
                <span className="star">*</span> Ridge capping
              </Typography>
              <Typography gutterBottom className="tiles">
                <span className="star">*</span> Sheds & external toilets
              </Typography>
              <Typography className="tiles">
                <span className="star">*</span> Wall sheeting – external
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Asbestos;
