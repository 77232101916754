import React from "react";
import { Box } from "@mui/material";
import { Container } from "@mui/material";
import { Typography } from "@mui/material";
import CustomButton from "../Common/CustomButton";
import "./agent.css";

const BuyerAgents = () => {
  return (
    <Container>
      <Box mt={6}>
        <Typography className="estate health" gutterBottom mb={4}>
        WHY OPT FOR OUR SERVICES
        </Typography>

        <Typography  gutterBottom m={2}>
       <span className="serve-1">1. Reliable Assurance : </span> <span className="frame">  All our Building And Pest Inspectors undergo
thorough vetting and checks and hold comprehensive professional
indemnity insurance, ensuring unwavering reliability.</span>
        </Typography>
        <Typography  gutterBottom m={2}>
        <span className="serve-1">2. Transparent Terms : </span><span className="frame"> Unlike most inspection reports with complex
limitations and exclusions pages, we keep it simple. Our terms and
conditions are concise, easy to understand, and straightforward,
eliminating confusion.</span>
        </Typography>
        <Typography  gutterBottom m={2}>
        <span className="serve-1">3. Clear Communication : </span><span className="frame">  You don&#39;t need a construction background to
comprehend our reports. We employ diagrams, photos, and everyday
language to articulate issues and provide understandable guidance
on necessary actions.</span>
        </Typography>
     
        <Typography gutterBottom m={2}>
        <span className="serve-1">  4. Swift Reporting : </span> <span className="frame"> One Stop Service Building and Pest Inspectors
streamline the reporting process with the newest software. This
means timely completion and hassle-free email delivery of your
comprehensive report, facilitating prompt decision-making .</span>
        </Typography>
       
 
        <Typography className="serve" gutterBottom>
        The comprehensive report will encompass the following aspects:
        </Typography>
      

        <Typography  gutterBottom m={2}>
       <span className="serve-1">1. Urgent and severe safety hazards: </span> <span className="frame">  AThese include building elements or
situations that pose an immediate or potential threat to the well-being of
individuals.</span>
        </Typography>
        <Typography  gutterBottom m={2}>
        <span className="serve-1">2. Major defects: </span><span className="frame"> These are significant issues that require rectification to prevent
unsafe conditions, loss of utility, or further deterioration of the property.</span>
        </Typography>
        <Typography  className="serve-1" gutterBottom  pl={2}><span>3. Minor defects are typically routine maintenance matters or expected property
upkeep.</span></Typography>
        <Typography  gutterBottom m={2}>
        <span className="serve-1">4. Suspected timber pest activity: </span><span className="frame">  This includes an assessment of termites,
timber borers, fungal decay, wood rot, or any other pest infestations at the
time of the inspection.
on necessary actions.</span>
        </Typography>
     
        <Typography gutterBottom m={2}>
        <span className="serve-1">5. Condition of the electrical system: </span> <span className="frame"> This involves an evaluation of the electrical
meter board, switchboard, visible wiring in the ceiling void, and the overall
condition of the electrical installation, including any installed solar systems.
This is a visual inspection, but if requested, testing of electrical appliances can
be arranged. I hold an Electrical License (PGE 202119).</span>
        </Typography>

        
        <Typography  gutterBottom m={2}>
       <span className="serve-1">6. Condition of the installed air conditioning system:</span> <span className="frame"> This entails visually
inspecting the system&#39;s condition. No service testing will be done, but a
complete system service, performance testing, and heat load calculation can
be arranged if you&#39;d like. I possess a Refrigeration License (L053509).</span>
        </Typography>
        <Typography  gutterBottom m={2}>
       <span className="serve-1">7. Condition of the property&#39;s plumbing system: </span> <span className="frame">This involves a visible inspection
of the plumbing system, with a water pressure test conducted to identify
potential leaks in the water piping.</span>
        </Typography>
       
        <Typography className="serve-1" gutterBottom pl={2}>
        <span>
        8. Identification of all Asbestos materials in the building or on-site.

        </span>

        </Typography>
        <Typography className="serve-1" gutterBottom pl={2} >
       9. Identification of any Mould and Fungal growth infestation in the property.
        </Typography>
      <Typography className="servedays" gutterBottom>
        The purpose of this service is to provide you with detailed advice on the property’s
condition at the time of the inspection, assisting you in making an informed decision
regarding your investment.
        </Typography>

        <Typography className="servedays" gutterBottom>Rest assured that the inspection will follow the following Australian Standards:</Typography>
      <Box mb={2}>
        <Typography m={2} sx={{fontStyle:'italic'}}>Australian Standard ® AS 4349.0 Inspection of buildings Part 0: General requirements</Typography>
        <Typography m={2} sx={{fontStyle:'italic'}}>Australian Standard ® AS 4349.1 Inspection of facilities Part 1: Pre-purchase
inspections— Residential building</Typography>
        <Typography m={2} sx={{fontStyle:'italic'}}>Australian Standard™ AS- 4349.3 Inspection of facilities Part 3: Timber pest inspections</Typography>
      </Box>
      </Box>
    </Container>
  );
};

export default BuyerAgents;
