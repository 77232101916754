import React from "react";
import Breadcrumb from "../Components/Common/BreadCrumb";
import Story from "../Components/Story";
import { Box, Grid } from "@mui/material";
import { Container } from "@mui/material";
import Quote from "../Components/Quote";
import AboutViews from "../Assets/Frame 1000001901.jpg";

const About = () => {
  const currentPath = "/about";
  const message = "About";

  return (
    <>
      <Breadcrumb path={currentPath} message={message} />
      <Container sx={{ padding: "0px !important" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            <Story />
          </Grid>
          <Grid item xs={12} md={5} mb={5}>
            <Quote />
            <Box sx={{padding: "0px 10px"}}>
              <img
                style={{ width: "100%", height: "100%" }}
                src={AboutViews}
                alt="About View"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default About;
