import React, { useState, useEffect } from "react";
import {
  Container,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  TextareaAutosize,
  Grid,
  Box,
  useTheme,
  Divider,
  Typography,
} from "@mui/material";
import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import "./foot.css";
import Logo from "../../../Assets/ossLogo.jpeg";
import CustomButton from "../CustomButton";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "@emailjs/browser";

const validationSchema = yup.object({
  email: yup.string("Enter your email").required("Email is required"),
  name: yup.string("Enter your Name").required("Name is required"),
  mobile: yup
    .number("Enter your Mobile Number")
    .required("Mobile Number is required"),
  message: yup.string("Enter your Message").required("Message is required"),
  site: yup.string("Enter your Message").required("Site is required"),
});

const siteName = ["Google", "Facebook"];

function getStyles(name, site, theme) {
  return {
    fontWeight:
      site.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Footer = () => {
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  const [site, setSite] = useState([]);
  const theme = useTheme();
  function onChange() {
    setVerified(true);
  }

  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    serviceType: "",
    message: "",
    site: "",
    verification: "",
  };
  const onSubmit = async (values, { resetForm }) => {
    if (!verified) {
      setError("Please verify you're not a robot.");
      return;
    } else {
      const payload = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        message: values.message,
        site: values.site,
        verification: values.verification,
      };

      await emailjs
        .send(
          "service_t21t9wh",
          "template_btu40mf",
          payload,
          "SWWk9No7dt1PS6irk"
        )
        .then(
          () => {
            resetForm();
          },
          () => {
            // console.log("Email Failed!"); required later
          }
        );
      toast.success("Your form has been submitted successfully. Thanks!");
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSite(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    formik.setFieldValue("site", event.target.value);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  useEffect(() => {
    if (verified) {
      setError("");
    }
  }, [verified]);

  return (
    <>
      <Box className="foot">
        <Box>
          <Typography className="team">
            Have a question? Call our friendly support team at{" "}
            <span>
              <a href="tel:0499542283">+61 0499542283</a>
            </span>
          </Typography>
        </Box>
        <Container>
          <Grid container spacing={2} className="cent">
            <Grid item xs={12} sm={6} md={3} className="losted">
              <Link to="/">
                <img
                  src={Logo}
                  width="100%"
                  height="100%"
                  style={{ maxWidth: "250px" }}
                  alt="Logo"
                />
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={3} className="losted">
              <Typography variant="h5" gutterBottom>
                Quick Links
              </Typography>
              <Link to="/" className="routing">
                <Typography className="host" gutterBottom>
                  Home
                </Typography>
              </Link>
              <Link to="/about" className="routing">
                <Typography className="host" gutterBottom>
                  About
                </Typography>
              </Link>
              <Link to="/services/inspection" className="routing">
                <Typography className="host" gutterBottom>
                  Pre- Purchase Building Inspections
                </Typography>
              </Link>
              <Link to="/services/timber-pest-inspections" className="routing">
                <Typography className="host" gutterBottom>
                  Timber Pest Inspections
                </Typography>
              </Link>
              <Link to="/services/new-construction-stage" className="routing">
                <Typography className="host" gutterBottom>
                  New Construction Stage Inspections
                </Typography>
              </Link>
              <Link to="/services/tax-depreciation" className="routing">
                <Typography className="host" gutterBottom>
                  Tax Depreciation
                </Typography>
              </Link>
              <Link to="/services/pool-safety" className="routing">
                <Typography className="host" gutterBottom>
                  Pool Compliance Certification
                </Typography>
              </Link>
              <Link to="/services/asbestos-awareness" className="routing">
                <Typography className="host" gutterBottom>
                  Asbestos Inspection & Register
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={2} className="losted">
              <Link to="/case-studies" className="routing">
                <Typography className="host" gutterBottom>
                  Case Studies
                </Typography>
              </Link>
              <Link to="faqs" className="routing">
                <Typography className="host" gutterBottom>
                  FAQ's
                </Typography>
              </Link>
              <Link to="/contact" className="routing">
                <Typography className="host" gutterBottom>
                  Contact
                </Typography>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={4} className="losted" mt={2}>
              <Typography variant="h5" mb={2}>
                Get A Free Quote
              </Typography>
              <Box component="form" onSubmit={formik.handleSubmit}>
                <Grid container rowSpacing={0}>
                  <Grid
                    item
                    xs={12}
                    lg={6}
                    sx={{ pr: { xs: "0px !important", lg: "10px !important" } }}
                  >
                    <TextField
                      className="fields"
                      fullWidth
                      size="small"
                      {...formik.getFieldProps("name")}
                      onChange={(e) => {
                        formik.setFieldValue("name", e.target.value);
                      }}
                      sx={{ backgroundColor: "#fff", mb: 0 }}
                      placeholder="Name"
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <Typography
                        sx={{
                          textAlign: "start",
                          marginLeft: "4px",
                          fontSize: "15px",
                          fontWeight: "600",
                          mt: -2,
                          mb: 1,
                        }}
                        color="error"
                      >
                        {formik.errors.name}
                      </Typography>
                    ) : null}
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <TextField
                      className="fields"
                      size="small"
                      type="email"
                      {...formik.getFieldProps("email")}
                      onChange={(e) => {
                        formik.setFieldValue("email", e.target.value);
                      }}
                      sx={{ backgroundColor: "#fff", mb: 0 }}
                      placeholder="Email"
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <Typography
                        sx={{
                          textAlign: "start",
                          marginLeft: "4px",
                          fontSize: "15px !important",
                          fontWeight: "600 !important",
                          mb: 1,
                          mt: -2,
                        }}
                        color="error"
                      >
                        {formik.errors.email}
                      </Typography>
                    ) : null}
                  </Grid>
                </Grid>
                <TextField
                  className="fields"
                  size="small"
                  type="number"
                  {...formik.getFieldProps("mobile")}
                  onChange={(e) => {
                    formik.setFieldValue("mobile", e.target.value);
                  }}
                  sx={{ backgroundColor: "#fff", mb: 2 }}
                  placeholder="Mobile"
                />
                {formik.touched.mobile && formik.errors.mobile ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "15px",
                      fontWeight: "600",
                      mt: -2,
                      mb: 1,
                    }}
                    color="error"
                  >
                    {formik.errors.mobile}
                  </Typography>
                ) : null}
                <FormControl fullWidth>
                  <Select
                    {...formik.getFieldProps("site")}
                    displayEmpty
                    value={site}
                    label="site"
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>Where did you hear about us?</em>;
                      }

                      return selected.join(", ");
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      color: "grey",
                      backgroundColor: "#fff",
                      mb: 2,
                      borderRadius: "4px !important",
                    }}
                  >
                    {siteName.map((site) => (
                      <MenuItem
                        key={site}
                        value={site}
                        style={getStyles(site, site, theme)}
                      >
                        {site}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.site && formik.errors.site ? (
                    <Typography
                      sx={{
                        textAlign: "start",
                        marginLeft: "4px",
                        fontSize: "15px",
                        fontWeight: "600",
                        mt: -2,
                        mb: 1,
                      }}
                      color="error"
                    >
                      {formik.errors.site}
                    </Typography>
                  ) : null}
                </FormControl>
                <TextareaAutosize
                  className="fields message"
                  minRows={3}
                  {...formik.getFieldProps("message")}
                  onChange={(e) => {
                    formik.setFieldValue("message", e.target.value);
                  }}
                  sx={{ backgroundColor: "#fff" }}
                  placeholder="Message"
                />
                {formik.touched.message && formik.errors.message ? (
                  <Typography
                    sx={{
                      textAlign: "start",
                      marginLeft: "4px",
                      fontSize: "15px",
                      fontWeight: "600",
                      mt: -2,
                      mb: 1,
                    }}
                    color="error"
                  >
                    {formik.errors.message}
                  </Typography>
                ) : null}
                <ReCAPTCHA
                  sitekey="6LfN8C8pAAAAAB9Jbn2ubQJf9ISCl2IAVn-_sN5w"
                  onChange={onChange}
                  width="250px !important"
                />
                {error && (
                  <p
                    style={{
                      color: "#d9534f",
                      fontSize: "15px",
                      fontWeight: "700 !important",
                    }}
                    color="error"
                  >
                    {error}
                  </p>
                )}
                <CustomButton
                  btnText="Send Message"
                  buttonStyle="sender"
                  btntype="submit"
                />
              </Box>
            </Grid>
          </Grid>
          <Divider color="darkgray" />
          <Grid container spacing={2} py={3.5}>
            <Grid item xs={12} sm={10}>
              <Typography
                className="premium study"
                sx={{
                  marginBottom: { xs: "15px !important", sm: "0px !important" },
                }}
              >
                © Copyright 2024 One-Stop Service Building Inspections. |
                Website designed by
                <a
                  href="https://www.premiumranks.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="match"
                >
                  <span className="catch">&nbsp; Premium Ranks</span>
                </a>
              </Typography>
            </Grid>
            <Grid item xs={6} sm={2} className="icons">
              <FacebookSharpIcon />
              <InstagramIcon />
              <EmailIcon />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <ToastContainer position="bottom-left" />
    </>
  );
};

export default Footer;
