import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "./test.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        background: "#34A9E2",
        padding: 10,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "25px",
        height: "25px",
        position: "absolute",
        right: "-37px",
      }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        background: "#34A9E2",
        color: "red",
        padding: 10,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "25px",
        height: "25px",
        position: "absolute",
        left: "-37px",
      }}
      onClick={onClick}
    />
  );
}
const Review = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const reiewData = [
    {
      id: 1,
      review: `What a great service! Prompt, affordable and a trusted inspector. I have personally used multiple times for my real estate clients looking to purchase.`,
      name: "Kristy Mansey",
      area: "Sunshine Coast",
    },
    {
      id: 2,
      review: `"Warren did a pre-purchase inspection report for my wife and I, as suggested by our realtor to assist in the sale of our home. We found Warren to be thorough, knowledgeable, professional, and friendly. We would have no hesitation in using him again."`,
      name: "A Poyser",
      area: "Sunshine Coast",
    },
    {
      id: 3,
      review: `"My wife and I would highly recommend Warren Smith as a building inspector. He is very thorough, professional and provides exceptional service. He saved us from making a 1.3 million dollar mistake! He's worth his weight in gold!"`,
      name: "R & S Jones",
      area: "Sunshine Coast",
    },
    {
      id: 4,
      review: `"If you're about to make the biggest investment of your life, it's important that every "i" is dotted and every "t" crossed. When there are so many things at stake we would never recommend going with anyone but Trusted Building Inspections under Wazza's supervision. You'll be glad you did when he brings his years of experience to bear on this huge undertaking while leveraging cutting-edge technology where appropriate!"`,
      name: "W & D Paterson",
      area: "Sunshine Coast",
    },
  ];
  return (
    <Box className="testBg">
      <Container
        sx={{
          pt: 8,
          pb: 10,
          fontFamily: "muli !important",
        }}
      >
        <Container
          sx={{
            maxWidth: "1300px !important",
            mt: 1,
            padding: "0px !important",
          }}
        >
          {/* <Grid container sapcing={2}> */}
          {/* <Grid item xs={12} md={4}> */}{" "}
          <Typography className="says" gutterBottom>
            WHEN SHOULD I GET IT DONE?
          </Typography>{" "}
          <Typography className="monial" gutterBottom>
            You are engage us at any time during the construction. However most
            people engage us as construction commences to complete inspections
            at all stages of the build.
          </Typography>{" "}
          <Typography className="reviews">RENOVATING OR EXTENDING?</Typography>
          <Typography className="fact" gutterBottom>
            You may not be undertaking a complete new home build, but the
            expenditure for some renovations and extensions can be just as
            costly and in some instances the work is in fact more complex as you
            integrate the new with the old.
          </Typography>
          <Typography className="fact" gutterBottom>
            Getting a renovation inspection report will help you plan your
            renovation - keeping it on track and on budget.
          </Typography>
          <Typography className="fact" gutterBottom>
            By engaging a building inspector to assist you in your relationship
            with your builder, you are also more likely to head off
            disagreements and arguments about cost and inclusions.
          </Typography>
          {/* </Grid> */}
          {/* <Grid item xs={12} md={8} px={5}>
              <Slider {...settings}>
                {reiewData?.map((item, index) => (
                  <Box
                    key={index}
                    sx={{
                      mt: 3,
                      display: "flex !important",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex !important",
                        flexDirection: "column",
                        justifyContent: "center",
                        px: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "Lato !important",
                          fontSize: "18px",
                          fontWeight: 200,
                          fontStyle: "normal",
                          textAlign: "justify",
                          mb: 3,
                        }}
                      >
                        {item?.review}
                      </Typography>
                      <Typography
                        gutterBottom
                        sx={{
                          fontFamily: "Lusitana !important",
                          fontWeight: 600,
                          fontSize: "16px",
                          textAlign: "left",
                        }}
                      >
                        {item?.name}
                      </Typography>
                      <Typography
                        gutterBottom
                        sx={{
                          fontFamily: "Lato !important",
                          color: "black",
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                        varient="h5"
                      >
                        {item?.area}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Slider>
            </Grid> */}
          {/* </Grid> */}
        </Container>
      </Container>
    </Box>
  );
};

export default Review;
