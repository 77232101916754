import * as React from "react";
import { styled } from "@mui/material/styles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "./quest.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderTop: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <PlayArrowIcon sx={{ fontSize: "0.9rem" }} className="frequently" />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  // '& .css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root': {
  //   backgroung: theme.spacing(1),
  // },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container sx={{ my: 10 }}>
      <Typography className="sale">PRE-SALE INSPECTIONS</Typography>
      <Typography className="vendor">REAL ESTATE & VENDORS</Typography>
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography className="frequently">
          WHY IS A HEALTH CHECK INSPECTION NECASSARY?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="prior" gutterBottom>
          The number one reason is cost in other words lots of $$ 'Prevention
          is better than cure ' When you know your property’s maintenance
          defects , you can make an informative decision on how to proceed to
          keep maximizing the value of your property.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography className="frequently">
          WHAT IS INCLUDED?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="prior">
          Along with a comprehensive report of all major,minor defects and
          structural issues, We will include cost effective ideas for repairs,
          refurbishments, and replacements. Otherwise we can project manage all
          the necessary quotes, repair and maintenance work at a minor cost to
          make sure it is at done at current Australian standard.
          </Typography>
        </AccordionDetails>
      </Accordion>
    
      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
          <Typography className="frequently">WHAT IS THE COST?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="prior">
            * &nbsp; 3 x Bedroom / 1 x Bathroom $320 Including GST.
          </Typography>
          <Typography className="prior">
            * &nbsp; 4 x Bedroom / 2 x Bathroom $380 Including GST.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Typography className="short" mt={4} gutterBottom>
        Contact us today to book Pre-Sale Inspection
      </Typography>
      <Typography className="short">
        Available on short notice 7 to 7 / 7 days a week
      </Typography>
    </Container>
  );
}
