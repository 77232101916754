import React from "react";
import ContactForm from "../Components/ContactForm";
import ServiceBanner from "../Components/ServiceBanner";
import Breadcrumb from "../Components/Common/BreadCrumb";

const AsbestosAwareness = () => {
  const currentPath = "Asbestos Inspection and Register";
  const message = "Asbestos Inspection and Register";
  return (
    <div>
    <Breadcrumb path={currentPath} message={message} />
      <ContactForm />
      <ServiceBanner />
    </div>
  );
};

export default AsbestosAwareness;
