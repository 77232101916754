import React from "react";
import HeroSection from "../Components/HeroSection";
import Counts from "../Components/Counts";
import Experience from "../Components/Experience";
import Testimonial from "../Components/Testimonial";
import Studies from "../Components/Studies";
import ServiceBanner from "../Components/ServiceBanner";

const Home = () => {
  return (
    <div>
      <HeroSection />
      <ServiceBanner />
      <Counts />
      <Experience />
      <Testimonial />
      <Studies />
    </div>
  );
};

export default Home;
