import React, { useState, useEffect } from "react";
import "./forming.css";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  SelectChangeEvent,
  OutlinedInput,
  useTheme,
} from "@mui/material";
import { Card } from "@mui/material";
import emailjs from "@emailjs/browser";
import { TextField } from "@mui/material";
import { Typography } from "@mui/material";
import { FormControl } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import CustomButton from "../Common/CustomButton";

const siteName = ["Google", "Facebook"];

function getStyles(name, site, theme) {
  return {
    fontWeight:
      site.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchema = yup.object({
  email: yup.string("Enter your email").required("Email is required"),
  name: yup.string("Enter your Name").required("Name is required"),
  mobile: yup
    .number("Enter your Mobile Number")
    .required("Mobile Number is required"),
  message: yup.string("Enter your Message").required("Message is required"),
  site: yup
    .string("Where did you hear about us?")
    .required("Site is required"),
});

const Contact = () => {
  const theme = useTheme();

  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);

  const [site, setSite] = useState([]);

  function onChange() {
    setVerified(true);
  }

  const initialValues = {
    name: "",
    email: "",
    mobile: "",
    serviceType: "",
    message: "",
    site: "",
    verification: "",
  };
  const onSubmit = async (values, { resetForm }) => {
    if (!verified) {
      setError("Please verify you're not a robot.");
      return;
    } else {
      const payload = {
        name: values.name,
        email: values.email,
        mobile: values.mobile,
        message: values.message,
        site: values.site,
        verification: values.verification,
      };

      await emailjs
        .send(
          "service_t21t9wh",
          "template_btu40mf",
          payload,
          "SWWk9No7dt1PS6irk"
        )
        .then(
          () => {
            setSite("");
            resetForm();
          },
          () => {
            // console.log("Email Failed!");
          }
        )
        .catch((e) => {
          console.log(e);
        });
      toast.success("Your form has been submitted successfully. Thanks!");
    }
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSite(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    formik.setFieldValue("site", event.target.value);
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });
  useEffect(() => {
    if (verified) {
      setError("");
    }
  }, [verified]);

  return (
    <>
      <Box mt={10} component="form" onSubmit={formik.handleSubmit}>
        <Card
          className="information"
          sx={{
            padding: "30px !important",
            margin: "-30px auto 40px !important",
            maxWidth: { xs: "100% !important", sm: "100% !important" },
          }}
        >
          <Typography className="quotation">Get A Free Quote</Typography>
          <TextField
            fullWidth
            size="small"
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              formik.setFieldValue("name", e.target.value);
            }}
            sx={{
              backgroundColor: "#fff",
              mb: 2,
              borderRadius: "4px !important",
            }}
            placeholder="Full Name"
          />
          {formik.touched.name && formik.errors.name ? (
            <Typography
              sx={{
                textAlign: "start",
                marginLeft: "4px",
                fontSize: "15px",
                fontWeight: "600",
                mt: -2,
                mb: 1,
              }}
              color="error"
            >
              {formik.errors.name}
            </Typography>
          ) : null}
          <TextField
            fullWidth
            size="small"
            type="email"
            {...formik.getFieldProps("email")}
            onChange={(e) => {
              formik.setFieldValue("email", e.target.value);
            }}
            sx={{
              backgroundColor: "#fff",
              mb: 2,
              borderRadius: "4px !important",
            }}
            placeholder="Email Address"
          />
          {formik.touched.email && formik.errors.email ? (
            <Typography
              sx={{
                textAlign: "start",
                marginLeft: "4px",
                fontSize: "15px !important",
                fontWeight: "600 !important",
                mt: -2,
                mb: 1,
              }}
              color="error"
            >
              {formik.errors.email}
            </Typography>
          ) : null}
          <TextField
            fullWidth
            size="small"
            type="number"
            {...formik.getFieldProps("mobile")}
            onChange={(e) => {
              formik.setFieldValue("mobile", e.target.value);
            }}
            sx={{
              backgroundColor: "#fff",
              mb: 2,
              borderRadius: "4px !important",
            }}
            placeholder="Phone Number"
          />
          {formik.touched.mobile && formik.errors.mobile ? (
            <Typography
              sx={{
                textAlign: "start",
                marginLeft: "4px",
                fontSize: "15px",
                fontWeight: "600",
                mt: -2,
                mb: 1,
              }}
              color="error"
            >
              {formik.errors.mobile}
            </Typography>
          ) : null}
          <FormControl fullWidth>
            <Select
              {...formik.getFieldProps("site")}
              displayEmpty
              value={site}
              label="site"
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>Where did you hear about us?</em>;
                }

                return selected.join(", ");
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                color: "grey",
                backgroundColor: "#fff",
                mb: 2,
                borderRadius: "4px !important",
              }}
            >
              {siteName.map((site) => (
                <MenuItem
                  key={site}
                  value={site}
                  style={getStyles(site, site, theme)}
                >
                  {site}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.site && formik.errors.site ? (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "4px",
                  fontSize: "15px",
                  fontWeight: "600",
                  mt: -2,
                  mb: 1,
                }}
                color="error"
              >
                {formik.errors.site}
              </Typography>
            ) : null}
          </FormControl>

          <FormControl fullWidth>
            <TextareaAutosize
              className="fields message talks"
              minRows={3}
              multiline
              {...formik.getFieldProps("message")}
              onChange={(e) => {
                formik.setFieldValue("message", e.target.value);
              }}
              sx={{
                backgroundColor: "#fff",
                mb: "20px !important",
                borderRadius: "4px !important",
              }}
              placeholder="Message"
            />
            {formik.touched.message && formik.errors.message ? (
              <Typography
                sx={{
                  textAlign: "start",
                  marginLeft: "4px",
                  fontSize: "15px",
                  fontWeight: "600",
                  mt: -2,
                  mb: 1,
                }}
                color="error"
              >
                {formik.errors.message}
              </Typography>
            ) : null}
          </FormControl>
          <ReCAPTCHA
            sitekey="6LfN8C8pAAAAAB9Jbn2ubQJf9ISCl2IAVn-_sN5w"
            onChange={onChange}
            width="250px !important"
          />
          {error && (
            <p
              style={{
                color: "#d9534f",
                fontSize: "15px",
                fontWeight: "600 !important",
              }}
              color="error"
            >
              {error}
            </p>
          )}
          <CustomButton
            btnText="Send Message"
            buttonStyle="sender"
            width="180px"
            height="46px"
            btntype="submit"
          />
        </Card>
      </Box>
      <ToastContainer position="bottom-left" />
    </>
  );
};

export default Contact;
