import React, { useEffect, useState } from "react";
import {  Container } from "@mui/material";
import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import CustomButton from "../Common/CustomButton";
import "./counter.css";
import { Link } from "react-router-dom";

const CountDetails = () => {
  const [counts, setCounts] = useState({
    inspections: 0,
    clients: 0,
    years: 0,
    satisfaction: 0,
  });

  const targetCounts = {
    inspections: 2200,
    clients: 630,
    years: 40,
    satisfaction: 96,
  };

  const animationDuration = 1000; // 1 second

  useEffect(() => {
    const animate = () => {
      let animationFrameId;
      const startTime = Date.now();

      const updateCounts = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - startTime;

        if (elapsedTime < animationDuration) {
          const updatedCounts = {};

          for (const key in targetCounts) {
            updatedCounts[key] = Math.floor(
              (elapsedTime / animationDuration) * targetCounts[key]
            );
          }

          setCounts(updatedCounts);
          animationFrameId = requestAnimationFrame(updateCounts);
        } else {
          setCounts(targetCounts);
        }
      };

      updateCounts();

      return () => {
        cancelAnimationFrame(animationFrameId);
        setCounts(targetCounts);
      };
    };

    animate();

    return () => {
      // Cleanup if component unmounts
      setCounts(targetCounts);
    };
  }, []);
  return (
    <Container className="year">
      {/* <Box className="client">
        <Box className="join industry">
          <Typography className="counting">+{counts.inspections}</Typography>
          <Typography className="category">Building Inspections</Typography>
        </Box>
        <Box className="join industry">
          <Typography className="counting">{counts.clients}</Typography>
          <Typography className="category">Clients</Typography>
        </Box>
        <Box className="join industry">
          <Typography className="counting">{counts.years}</Typography>
          <Typography className="category">Years In The Industry</Typography>
        </Box>
        <Box className="join">
          <Typography className="counting">{counts.satisfaction}%</Typography>
          <Typography className="category">Customer Satisfaction</Typography>
        </Box>
      </Box> */}
      <br />
      <Typography className="trust">
        BUILDING A HOME? BUYING OFF THE PLAN?
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Typography className="mind">NEW CONSTRUCTION INSPECTIONS</Typography>
        </Grid>
        <Grid item xs={12} md={10}>
        <Typography className="decline" gutterBottom>
           <h3></h3> <p><b className="peace">• Pre-Pour:</b> We check that all steel reinforcement, membrane, and other underfloor elements are placed correctly. </p>
        </Typography></Grid>
        <Grid item xs={12} md={10}>
        <Typography className="decline" gutterBottom>
           <h3></h3> <p><b className="peace">• Slab and frames:</b>We check the slab, the wall and roof framing, bracing and tie-downs for compliance with Australian standards plans and specifications and ensure good quality workmanship.  </p>
        </Typography></Grid>
        <Grid item xs={12} md={10}>
        <Typography className="decline" gutterBottom>
           <h3></h3> <p><b className="peace">• Wall and roof cladding: </b> We check the external walls and roofing to comply with Australian standards, plans, and specifications and ensure good workmanship. We also check electrical and plumbing preliminaries at this stage.  </p>
        </Typography></Grid>
        <Grid item xs={12} md={10}>
        <Typography className="decline" gutterBottom>
           <h3></h3> <p><b className="peace">• Lockup:</b> We check internal claddings, second-fix items, and waterproofing before tile work to comply with Australian standards, plans and specifications and ensure good workmanship.  </p>
        </Typography></Grid>
        <Grid item xs={12} md={10}>
        <Typography className="decline" gutterBottom>
           <h3></h3> <p><b className="peace">•Final Inspection:</b>  we check all finishes inside and out for compliance with Australian standards, plans and specifications and ensure good workmanship.  </p>
        </Typography></Grid>
        {/* <Grid item xs={12} md={7} className="contract">
          <Box>
            <Typography className="peace">1. &nbsp; Contract Review</Typography>
            <Typography className="peace">2. &nbsp; Slab Stage</Typography>
            <Typography className="peace">
              3. &nbsp; Plate Height Stage
            </Typography>
          </Box>
          <Box>
            <Typography className="peace">4. &nbsp; Lock up stages</Typography>
            <Typography className="peace">
              5. &nbsp; Practical Completion Stage
            </Typography>
            <Typography className="peace">
              6. &nbsp; 6 Month Maintenance Warranty Stage
            </Typography>
          </Box>
        </Grid> */}
        <Link to="/contact" className="routing">
          <CustomButton
            btnText="Book Your Building Inspection Now!"
            buttonStyle="book"
          />
        </Link>
      </Grid>
    </Container>
  );
};

export default CountDetails;
